import { logo } from "../assets";
import { socialMedia } from "../constants";

const Navbar = () => {
  return (
    <nav className="w-full flex py-6 justify-between items-center navbar">
      <img
        src={logo}
        alt="10x dreams"
        className="w-[48px] h-[48px] sm:w-[64px] sm:h-[64px]"
      />

      <ul className="list-none flex items-center ">
        {socialMedia.map((social, index) => (
          <img
            key={social.id}
            src={social.icon}
            alt={social.id}
            className={`w-[21px] h-[21px] object-contain cursor-pointer ${
              index !== socialMedia.length - 1 ? "mr-6" : "mr-0"
            }`}
            onClick={() => window.open(social.link)}
          />
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
