// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_HOME = "/";
const ROOT_EXAMPLE = "/example";
// ----------------------------------------------------------------------

export const PATH_HOME = {
  root: ROOTS_HOME,
  portfolio: path(ROOTS_HOME, "portfolio"),
  configurators: path(ROOTS_HOME, "configurators"),
  services: path(ROOTS_HOME, "services"),
  aboutUs: path(ROOTS_HOME, "about-us"),
};

export const PATH_EXAMPLE = {
  root: ROOT_EXAMPLE,
  shirt: path(ROOT_EXAMPLE, "/shirt"),
  watch: path(ROOT_EXAMPLE, "/watch"),
  nick: path(ROOT_EXAMPLE, "/nick"),
  ring: path(ROOT_EXAMPLE, "/ring"),
  bag: path(ROOT_EXAMPLE, "/bag"),
  shoes: path(ROOT_EXAMPLE, "/shoes"),
  house: path(ROOT_EXAMPLE, "/house"),
  table: path(ROOT_EXAMPLE, "/table"),
  showConfigurator: path(ROOT_EXAMPLE, "/show-configurator"),
  techCollection: path(ROOT_EXAMPLE, "/tech-collection"),
  shoeLandingPage: path(ROOT_EXAMPLE, "/shoe-landing-page"),
  faucets: path(ROOT_EXAMPLE, "/faucets-highlight"),
};

export const PATH_PAGE = {
  page404: "/404",
  page500: "/500",
};
