import { Suspense, lazy } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import { MainLayout, SimpleLayout } from "../layouts";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={null}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    //  Routes
    {
      path: "/",
      element: <MainLayout />,
      children: [{ index: true, element: <Home /> }],
    },

    //  Routes
    {
      path: "*",
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// Home
const Home = Loadable(lazy(() => import("../pages/Home")));

// Error

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
