import {
  airbnb,
  backend,
  binance,
  coinbase,
  creator,
  dropbox,
  instagram,
  mobile,
  projectImages,
  send,
  shield,
  star,
  tiktok,
  web,
  youtube,
} from "../assets";
import { PATH_EXAMPLE, PATH_HOME } from "../routes/paths";

export const navLinks = [
  {
    id: "home",
    title: "Home",
    path: PATH_HOME.root,
  },
  {
    id: "configurator",
    title: "Configurators",
    path: PATH_HOME.configurators,
  },
];

export const services = [
  {
    title: "3D Product Modeling",
    icon: mobile,
  },
  {
    title: "3D Product To Video Ad",
    icon: backend,
  },
  {
    title: "Web & Mobile Store Development",
    icon: web,
  },
  {
    title: "Deployment & Maintenance",
    icon: creator,
  },
];

export const projects = [
  {
    name: "Meditaciones Diarias",
    description:
      "This is a free app that allows you to listen to hundreds of meditations, ranging from 15 to 30 minutes in duration. I have develop this one for BeeCode.",
    image: projectImages.meditaciones,
    source_code_link: "",
    app_store_link:
      "https://apps.apple.com/us/app/meditaciones-diarias/id1621721684",
    play_store_link: "",
  },
  {
    name: "iSmile",
    description:
      "This is a mobile app that allows you to control your meditation, get quotes and schedule meditation, I have develop this one for BeeCode.",

    image: projectImages.ismile,
    source_code_link: "",
    app_store_link: "",
    play_store_link:
      "https://play.google.com/store/apps/details?id=com.prestigia.ismile",
  },
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "3D Modeling",
    content: "Realistic 3D visualizations of your products",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Product Video",
    content: "Engaging and modern Intro or Ad videos for your product",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Online Store Development",
    content: "Build modern store with Shopify and other platforms",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content:
      "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    name: "Herman Jensen",
    title: "Founder & Leader",
    img: star,
  },
  {
    id: "feedback-2",
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: "Steve Mark",
    title: "Founder & Leader",
    img: star,
  },
  {
    id: "feedback-3",
    content:
      "It is usually people in the money business, finance, and international trade that are really rich.",
    name: "Kenn Gallagher",
    title: "Founder & Leader",
    img: star,
  },
];
export const videos = [
  {
    id: "video-chair",
    video: "https://www.youtube.com/embed/9239GJwfoyM?si=hptv-DKMUt9wP-mk",
  },

  {
    id: "video-airpods",
    video: "https://www.youtube.com/embed/xRqMiLnG-4s?si=erK5YCb7QDnGQtK_",
  },
  {
    id: "video-computer",
    video: "https://www.youtube.com/embed/DVQ9SZlkO1U?si=Do59pApqtjlfDFu0",
  },
  {
    id: "video-pepsi",
    video: "https://www.youtube.com/embed/Yv4pY8M3Bpo?si=45haKSNHThN9pxI5",
  },
  {
    id: "video-watch",
    video: "https://www.youtube.com/embed/SJ3FaDEjvds?si=zThcHpNMpTOCQ8kl",
  },
];

export const stats = [
  {
    id: "stats-1",
    title: "User Active",
    value: "3800+",
  },
  {
    id: "stats-2",
    title: "Trusted by Company",
    value: "230+",
  },
  {
    id: "stats-3",
    title: "Transaction",
    value: "$230M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Content",
        link: "https://www.10xDreams.com/content/",
      },
      {
        name: "How it Works",
        link: "https://www.10xDreams.com/how-it-works/",
      },
      {
        name: "Create",
        link: "https://www.10xDreams.com/create/",
      },
      {
        name: "Explore",
        link: "https://www.10xDreams.com/explore/",
      },
      {
        name: "Terms & Services",
        link: "https://www.10xDreams.com/terms-and-services/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "https://www.10xDreams.com/help-center/",
      },
      {
        name: "Partners",
        link: "https://www.10xDreams.com/partners/",
      },
      {
        name: "Suggestions",
        link: "https://www.10xDreams.com/suggestions/",
      },
      {
        name: "Blog",
        link: "https://www.10xDreams.com/blog/",
      },
      {
        name: "Newsletters",
        link: "https://www.10xDreams.com/newsletters/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "https://www.10xDreams.com/our-partner/",
      },
      {
        name: "Become a Partner",
        link: "https://www.10xDreams.com/become-a-partner/",
      },
    ],
  },
];

export const socialMedia = [
  {
    id: "social-media-3",
    icon: youtube,
    link: "https://www.youtube.com/@10xsale",
  },

  {
    id: "social-media-3",
    icon: tiktok,
    link: "https://www.tiktok.com/@10xsale_official",
  },
  {
    id: "social-media-1",
    icon: instagram,
    link: "https://www.instagram.com/tenxsale/",
  }
];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];
