import { Outlet } from "react-router-dom";
import { Footer, Navbar } from "../components";
import styles from "../style";

// ----------------------------------------------------------------------

export function MainLayout() {
  return (
    <>
      <div className="bg-primary  w-screen flex flex-col overflow-hidden">
        <div className={`${styles.paddingX} ${styles.flexCenter}`}>
          <div className={`${styles.boxWidth}`}>
            <Navbar />
          </div>
        </div>
        <div className="flex-1">

        <Outlet />
        </div>
        <div className="px-10">
          <Footer />
        </div>
      </div>
    </>
  );
}
export function SimpleLayout() {
  return (
    <>
      <Outlet />
    </>
  );
}
